import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Home from '../components/Home'
import Test from '../components/config/Test'
import Post from '../components/post/Post'
import Notfound from '../components/config/NotFound'
import Menu from '../components/main/Menu'
import MiPerfil from '../components/user/MiPerfil'
import Msg from '../components/user/Msg'
import Groups from '../components/groups/Groups'

const Routes = () =>{
    return(
        <Router>
            <Menu></Menu>
            <Switch>
                <Route path="/" exact>
                    <Home></Home>
                </Route>
                <Route path="/hello">
                    <Test></Test>
                </Route>
                <Route path="/post">
                    <Post></Post>
                </Route>
                <Route path="/perfil">
                    <MiPerfil></MiPerfil>
                </Route>
                <Route path="/mensajes">
                    <Msg></Msg>
                </Route>
                <Route path="/grupos">
                    <Groups></Groups>
                </Route>
                <Route>
                    <Notfound></Notfound>
                </Route>
            </Switch>
        </Router>
    )
}

export default Routes