import React,  { useState,useEffect  }  from 'react';
import { useSelector/* , useDispatch  */} from 'react-redux';
import { loginSelector } from '../../redux/slices/login.slice';
import jwt_decode from "jwt-decode";

const MainLayout = () =>{
    const [username,setUsername] = useState("");
    const {response}   = useSelector(loginSelector)
    
    useEffect(()=>{
        let session = {}
        let result = response.response
        if(result.session){
            session = jwt_decode(result.session)
            setUsername(session.user.name)
        }
        console.log(session.user);
        // eslint-disable-next-line
    },[])

    return(
        <div>
            <h2>{ username }</h2>
        </div>
    )
}

export default MainLayout