import { BaseValidation } from "../base.validation"

//aca se realizarán las validaciones de formato del inicino de sesión
// pendiente agregar directorio de utilities para validación de campos

//1) ambos campos no pueden estar vacíos
//2) el campo email debe ser de tipo email

export class ValidateFieldsLogin extends BaseValidation{
    constructor(){
        super()
        let emptyEmail = this.fieldValidationFactory.createInstance('empty','email','el email no puede estar vacío.')
        let emptyPassword = this.fieldValidationFactory.createInstance('empty','password','el password no puede estar vacío.')
        let emailEmail = this.fieldValidationFactory.createInstance('email','email','el email no es válido.')
        this.addValidator(emptyEmail)
        this.addValidator(emptyPassword)
        this.addValidator(emailEmail)
    }
}