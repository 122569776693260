import { UNDEF } from "../config/constants";

export class Utils{

    static isEmpty(text) {
        return (Utils.isUndfNull(text) || text.toString() === "")
    }
    
    static isUndfNull(text) {
        return (typeof text == UNDEF || text == null)
    }

    static isEmail(text){
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(text);
    }
}