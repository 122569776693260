import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const URL_POST_LOGIN = "https://johnsinapsis.co/testing/login"

const initialState = {loading:false, login:false, response:{"test":"parece estar funcionando bien"}};

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers:{
        getSession: state =>{
            state.loading = true
        },
        getSessionSuccess: (state,{payload})=>{
            state.response = payload
            state.loading = false
            state.login = true
        },
        getSessionFailure: (state,{payload})=>{
            state.loading = false
            state.login = false
            state.response = payload
        }
    }
})

//reducer
export default loginSlice.reducer

//actions
export const {getSession,getSessionSuccess,getSessionFailure} = loginSlice.actions

//selector
export const loginSelector = state => state.login

//api consumer
export const sigIn = (fields) => async(dispatch) =>{
    dispatch(getSession())
    try{
        const res = await axios.post(URL_POST_LOGIN,fields)
        //console.log(res.data);
        if(res.data.response.error)
            dispatch(getSessionFailure(res.data))
        else
            dispatch(getSessionSuccess(res.data))
    }
    catch(e){
        console.log("error++++++",e);
        dispatch(getSessionFailure(e))
    }
}
