import { FieldValidator } from "./field.validator";
import { Utils } from "../../shared/utils";

export class EmailFieldValidation extends FieldValidator {

    constructor(jpExpr, failMsg){
        super(jpExpr, failMsg)
        this.jpExpr = jpExpr
        this.failMsg = failMsg
    }

    isValid(fields){
        let field = fields[this.jpExpr]
        return Utils.isEmail( field )
    }

    addErrorResponse(field,msg){
        return msg;
    }
}