import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import store from '../store'

const REGISTERS_PER_PAGE = 10
const URL_GET_POST = "https://johnsinapsis.co/testing/posts"
const URL_ADD_ACTION_POST = "https://johnsinapsis.co/testing/post/actions"

const initialState = {loading:false, hasError:false, posts:[], page:1, nextPage:2, hasMore:true};

const postSlice = createSlice({
    name: "posts",
    initialState,
    reducers:{
        getPost: state =>{
            state.loading = true
        },
        getPostSuccess: (state,{payload})=>{
            state.posts = [...state.posts,...payload]
            state.nextPage = state.nextPage+1
            state.loading = false
            state.hasError = false
        },
        getPostFailure: (state)=>{
            state.loading = false
            state.hasError = true
        },
        updatePage: (state,{payload})=>{
            console.log("por el momento actualiza página---",payload);
            state.page = payload
        },
        setFinishContent:(state)=>{
            state.hasMore = false
        },
        updateActionUseInPostSuccess:(state,{payload})=>{
            let index = state.posts.findIndex(post => post.id === payload.id)
            if(index!==-1){
                state.posts[index].likes = payload.likes
                state.posts[index].dislikes = payload.dislikes
                if(payload.action === 'likes'){
                    !state.posts[index].likeUser ? state.posts[index]['likeUser'] = 1 :
                    state.posts[index].likeUser = 0
                }
                else{
                    !state.posts[index].dislikeUser ? state.posts[index]['dislikeUser'] = 1 :
                    state.posts[index].dislikeUser = 0
                }
            }
        }
    }

})

//reducer
export default postSlice.reducer

//actions
export const {getPost,getPostSuccess,getPostFailure,updateActionUseInPostSuccess,updatePage,setFinishContent} = postSlice.actions

//selector
export const postSelector = state => state.posts


//api consumer
export const getAllPosts = () => async(dispatch) =>{
    dispatch(getPost())
    try{
        let page = store.getState().posts.page
        //console.log("nueva forma",store.getState().posts.page);
        const res = await axios.get(URL_GET_POST+'?page='+page+"&registersPerPage="+REGISTERS_PER_PAGE)
        //+'?page='+
        if(res.data.length > 0){
            dispatch(getPostSuccess(res.data))
            console.log("---------------");
            dispatch(updatePage(page+1))
        }
        else{

            dispatch(setFinishContent())
        }
    }
    catch(e){
        console.log("error---",e);
        dispatch(getPostFailure())
    }
}

/* export const updatePageNext = (page) => (dispatch) =>{
    dispatch(updatePage(page))
} */

//para consumir la de incrementar los likes
export const addActionUserInPost = (params) => async(dispatch) =>{
    try{
        /* setTimeout(()=>{
            
        },2000) */
        if(!store.getState().login.response){
            console.error("No se encuentra autenticado. No tiene autorización para el actual recurso");
            dispatch(getPostFailure()) 
        }else{

            let token = store.getState().login.response.response.session
            let config = {headers:{"Authorization":`${token}`}}
            const res = await axios.put(URL_ADD_ACTION_POST,params,config)
            let {response} = res.data
            if(response.error){
                console.error(response.error);
                dispatch(getPostFailure())
            }
            else{
                let payload = {
                    id: response.post.id,
                    likes:response.post.likes,
                    dislikes: response.post.dislikes,
                    action: params.action
                }
                //console.log("--------///-------",payload);
                dispatch(updateActionUseInPostSuccess(payload))
                
            }
        }
    }
    catch(e){
        console.error("linea 80",e);
        dispatch(getPostFailure())
    }
}


