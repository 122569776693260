import { Link } from 'react-router-dom'
import { Navbar } from './Menu.styles'
import Dropdown from './Dropdown'
import Logo from '../../assets/img/vecindario-logo.svg'

const screenSize = window.screen.availWidth;

const Menu = () =>{
    return(
        <Navbar>
            <nav className="navbar navbar-default">
                <div className="container-fluid">
                    <div className="navbar-header">
                        <Link className="navbar-brand" to="/">
                            <img src={Logo} width={screenSize<435 ? "80" : "120"} height="50" alt="Logo"/>
                        </Link>
                        <Link className="navbar-brand" to="/">Home</Link>
                        <Link className="navbar-brand" to="/post">Post</Link>
                        <Link className="navbar-brand" to="/hello">Test</Link>
                    </div>
                    <Dropdown></Dropdown>
                </div>
            </nav>
        </Navbar>
    )
}

export default Menu