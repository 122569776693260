import { EmptyFieldValidation } from "./field/empty.field.validation";
import { EmailFieldValidation } from "./field/email.field.validator";

export const FIELD_VALIDATORS = {
    "empty": EmptyFieldValidation, 
    "email": EmailFieldValidation
}

export class FieldValidationFactory{
    static createInstance(name, jpExpr, failMsg) {
        const fConstructor = FIELD_VALIDATORS[name]
        return fConstructor ? new fConstructor(jpExpr, failMsg) : null
    }

}