import { Link } from 'react-router-dom'
import { NavDropdown } from 'react-bootstrap';
import styled from 'styled-components';

const StyleDropdown = styled.div`
    a{
        color: #FFF;
        text-decoration: none;
    }
    a:hover{
        color: white;
    }

    a:focus{
        color: white;
    }

    div{
        background-color: #ffd300;
    }
`

const DropDown = () =>{
    return(
        <StyleDropdown>

            <NavDropdown title="Menu" id="nav-dropdown">
            <NavDropdown.Item eventKey="4.1">
                <Link to="/perfil">Mi Perfil</Link>
            </NavDropdown.Item>
            <NavDropdown.Item eventKey="4.2">
                <Link to="/mensajes">Mensajes</Link>
            </NavDropdown.Item>
            <NavDropdown.Item eventKey="4.3">
                <Link to="/grupos">Grupos</Link>
            </NavDropdown.Item>
        </NavDropdown>
        </StyleDropdown>
    )
}

export default DropDown