export class FieldValidator{
    
    jpExpr
    failMsg
    msg

    constructor(jpExpr, failMsg){
        this.jpExpr = jpExpr
        this.failMsg = failMsg
    }

    evaluate(req, res){
        this.msg = this.failMsg
        let isValid = this.isValid(req, res)
        if(!isValid)
            this.addErrorResponse(res, this.msg)
        return isValid
    }

    isValid(field){
        return true
    }
    
    addErrorResponse (res , msg){}
} 
