import Routes from './routes'
import Footer from './components/main/Footer';

function App() {
  
  return(
    <div>
      <Routes></Routes>
      <Footer></Footer>
    </div>
  )
  
}

export default App;
