//import React, { useState,useEffect } from 'react';
import { useSelector } from 'react-redux';
import Login from './user/Login'
import MainLayout from './main/main';
import { loginSelector } from '../redux/slices/login.slice';
import { screenSize } from '../config/constants';
import '../index.css'


function Home(){
    const {login}   = useSelector(loginSelector)

    return (
        <main className="container" style={{height: screenSize < 435 ? "50vh":"75vh", background:"#fff"}}>
          { !login ? <Login></Login> :  <MainLayout></MainLayout>}
        </main>
    );

}

export default Home