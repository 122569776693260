import styled from "styled-components";


export const Navbar = styled.nav`
a {
  font-size: 1rem;
  color: #3b5998;
}


.navbar {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.navbar-default {
  /*background-color: #3b5998;*/
  background-color: #ffd300;
  border-color: #3b5998;
}

.navbar-default .navbar-brand{
  color: #fff;
}

.navbar-default .navbar-brand:focus,
.navbar-default .navbar-brand:hover {
  color: #fff;
}

.navbar-default .navbar-nav>li>a {
  color: #fff;
}

.navbar-default .navbar-nav>li>a:focus,
.navbar-default .navbar-nav>li>a:hover {
  color: #fff;
}

`