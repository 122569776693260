import { FieldValidator } from "./field.validator";
import { Utils } from "../../shared/utils";
//import { UNDEF,INV_FIELDS } from "../../config/constants";

export class EmptyFieldValidation extends FieldValidator {

    constructor(jpExpr, failMsg){
        super(jpExpr, failMsg)
        this.jpExpr = jpExpr
        this.failMsg = failMsg
    }

    isValid(fields){
        let field = fields[this.jpExpr]
        return !Utils.isEmpty( field )
    }

    addErrorResponse(field,msg){
        return msg;
    }
}
