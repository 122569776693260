import React, { useState,useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';
import { ValidateFieldsLogin } from '../../validations/rules/session.rules';
import { loginSelector,sigIn } from '../../redux/slices/login.slice';
import { FormStyle } from './ModalLogin.styles';
import { screenSize } from '../../config/constants';
import '../../index.css'

const rules = new ValidateFieldsLogin()

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: screenSize < 435 ? 350 : 400,
  bgcolor: 'background.paper',
  backgroundColor: 'var(--formColor)',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const ModalLogin = ({openModal,setOpenModal}) =>{
  
    //initialize redux hook
    const dispatch = useDispatch();
    const {loading,response}   = useSelector(loginSelector)

    //useStates
    const [email,setEmail] = useState("");
    const [password,setPassword] = useState("")
    const [validationEmail,setValidationEmail] = useState(true);
    const [validationPassword,setValidationPassword] = useState(true);
    const [error,setError] = useState("")
    const [loginStatus,setLoginStatus] = useState(false)

    useEffect(()=>{
        let result = response.response ? response.response : null
        if(result){
            if(result.error){
                setError(result.error)
            }
            else{
                //add user data
                setLoginStatus(true)
            }
        }
    },[response]) 

    useEffect(()=>{
        if(loginStatus){
            setError("")
            setOpenModal(false)
        }
        // eslint-disable-next-line
    },[loginStatus])

    const handleEmailChange = ({target:{value}}) => {
        setEmail(value);
        if(!value){
            setError("")
            setValidationEmail(false)
        }
        else{
            setValidationEmail(true)
        }
    }

    const handlePasswordChange = ({target:{value}}) => {
        setPassword(value)
        !value ? setValidationPassword(false): setValidationPassword(true)
    }

    const handlerClickLoginButton = () =>{
        let res = validationFields()
        if(res.error){
            setError(res.msg)
            if (res.field==='email')
                setValidationEmail(false)
            if (res.field==='password')
                setValidationPassword(false)
        }
        else{
            dispatch(sigIn({email,password}))
        }
    }

    const validationFields = () =>{
        let response = {error:false,msg:"",field:""}
        let res = rules.isValid({email,password},response)
        if(!res.valid){
            response.error = true
            response.msg = res.msg
            if(res.field)
                response.field = res.field
        }
        return response
    }
  
    const handleClose = () => setOpenModal(false);

  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <h2 className="text-center">Iniciar Sesión</h2>
            <p className="text-center">Debe iniciar sesión antes de continuar</p>
            <FormStyle>
                <form>
                    { loading && <CircularProgress disableShrink sx={{textAlign:"center"}}/>}
                    { response.response && validationEmail && validationPassword &&
                    <div>
                        <h4 style={{color:'red'}}>{ error }</h4>
                    </div>
                    }
                    <div>
                    { !validationEmail  ?
                        <label style={{color:'red'}}>{ error }</label>
                        : ''
                        }
                        <input type="email" placeholder="Email" value={email} onChange={handleEmailChange}/>
                    </div>
                    <div>
                    { !validationPassword  ?
                        <label style={{color:'red'}}>{ error }</label>
                        : ''
                        }
                        <input 
                            type="password" 
                            placeholder="Password" 
                            value={password} 
                            onChange={handlePasswordChange} onKeyPress={(e)=>{ e.key==='Enter' && handlerClickLoginButton() }}
                        />
                    </div>
                    <div>
                        <button type="button" onClick={handlerClickLoginButton}>Login</button>
                    </div>
                </form>
            </FormStyle>
        </Box>
      </Modal>
    </div>
  );
}

export default ModalLogin
