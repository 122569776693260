import { 
    Box,
    Container,
    Row,
    Heading
 } from "./Footer.styles"

 import Logo from '../../assets/img/vecindario-logo.svg'

 const Footer = () => {
    return (
      <Box>
          <h1 style={{ color: "#ffd300", 
                   textAlign: "center", 
                   fontSize: "1em",
                   marginTop: "0px" }}> 
                   <img src={Logo} width="120" height="50" alt="Logo"/>&nbsp;
                   Tener casa sí es posible
      </h1>
        <Container>
          <Row>
            <Heading>Nosotros</Heading>
            <Heading>Servicios</Heading>
            <Heading>Contáctenos</Heading>
            <Heading>Privacidad</Heading>
          </Row>
        </Container>
      </Box>
    );
  };
  export default Footer;