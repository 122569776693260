import React, { useState,useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ValidateFieldsLogin } from '../../validations/rules/session.rules';
import { loginSelector,sigIn } from '../../redux/slices/login.slice';
import { StyleLoginSmall } from './Login.styles';
import { FormStyle } from './ModalLogin.styles';
import { screenSize } from '../../config/constants';
import '../../index.css'


const rules = new ValidateFieldsLogin()


const Login = () =>{
    
    
    //initialize redux hook
    const dispatch = useDispatch();
    const {response}   = useSelector(loginSelector)
    
    
    //useStates
    const [email,setEmail] = useState("");
    const [password,setPassword] = useState("")
    const [validationEmail,setValidationEmail] = useState(true);
    const [validationPassword,setValidationPassword] = useState(true);
    const [error,setError] = useState("")
    const [loginStatus,setLoginStatus] = useState(false)


    useEffect(()=>{
        let result = response.response ? response.response : null
        if(result){
            if(result.error){
                setError(result.error)
            }
            else{
                //add user data
                setLoginStatus(true)
                console.log(loginStatus);
            }
        }
        // eslint-disable-next-line
    },[response]) 

    const handleEmailChange = ({target:{value}}) => {
        setEmail(value);
        if(!value){
            setError("")
            setValidationEmail(false)
        }
        else{
            setValidationEmail(true)
        }
    }

    const handlePasswordChange = ({target:{value}}) => {
        setPassword(value)
        !value ? setValidationPassword(false): setValidationPassword(true)
    }

    const handlerClickLoginButton = () =>{
        let res = validationFields()
        if(res.error){
            setError(res.msg)
            if (res.field==='email')
                setValidationEmail(false)
            if (res.field==='password')
                setValidationPassword(false)
        }
        else{
            dispatch(sigIn({email,password}))
        }
    }

    const validationFields = () =>{
        let response = {error:false,msg:"",field:""}
        let res = rules.isValid({email,password},response)
        if(!res.valid){
            response.error = true
            response.msg = res.msg
            if(res.field)
                response.field = res.field
        }
        return response
    }

    /* store.subscribe(()=>{
        let res = store.getState().session
        if (res.field==='email')
            setValidationEmail(false)
        if (res.field==='password')
            setValidationPassword(false)
    }); */


    return (
        <div>
            <h1 className="text-center">
                Welcome to Vencindario! <br/>
                <StyleLoginSmall>
                    <small>A simple React Project.</small>
                </StyleLoginSmall>
            </h1>
            <FormStyle style={
                {
                    textAlign:"center", 
                    paddingTop:"1%", paddingBottom:"1%", 
                    maxWidth:"500px",
                    marginLeft: screenSize < 435 ? "" : "28%"
                }
            }>
            <form style={
                {
                    background:"var(--formColor)", 
                    width: screenSize < 435 ? 300 : 400, 
                    marginLeft: screenSize < 435 ?"5%" : "calc(50% - 200px)"}}>
                { response.response && validationEmail && validationPassword &&
                <div>
                    <h2 style={{color:'red'}}>{ error }</h2>
                </div>
                }
                <div>
                    <input type="email" placeholder="enter your email" value={email} onChange={handleEmailChange}/>
                    { !validationEmail  ?
                    <label style={{color:'var(--formColor)'}}>{ error }</label>
                    : ''
                    }
                </div>
                <div>
                    <input type="password" placeholder="Password" value={password} onChange={handlePasswordChange}/>
                    { !validationPassword  ?
                    <label style={{color:'var(--formColor)'}}>{ error }</label>
                    : ''
                    }
                </div>
                <div>
                    <button type="button" onClick={handlerClickLoginButton}>Login</button>
                </div>
            </form>
            </FormStyle>
        </div>
    )
}



export default Login