import React, { useEffect,useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import PostCard from "./PostCard"
import { postSelector, getAllPosts, addActionUserInPost } from '../../redux/slices/post.slice'
import NotFound from '../config/NotFound'
import { loginSelector } from '../../redux/slices/login.slice';
import ModalLogin from '../user/ModalLogin'
/* import InfiniteScroll from "react-infinite-scroller"; */

const Post = () =>{


    //initialize redux hook
    const dispatch = useDispatch();
    const {posts,loading,hasError}   = useSelector(postSelector)
    const {login}   = useSelector(loginSelector)

    //useState
    const [openModal,setOpenModal] = useState(false)
    const [action,setAction] = useState("")
    const [tmpPostId,setTmpPostId] = useState(0)
    const [event,setEvent] = useState(false)
    const [temporal,setTemporal] =useState(false)

    //useEffect
    useEffect(()=>{
        dispatch(getAllPosts())
        // eslint-disable-next-line
    },[])

    useEffect(()=>{
        if(temporal){
            dispatch(getAllPosts()) 
            setTemporal(false)
        }
        // eslint-disable-next-line
    },[temporal])

    const handleLoadMore = (e)=>{
        
        let html = document.documentElement;
        //console.log("altura total del documento",html.scrollHeight);
        //console.log("scrolltop",html.scrollTop);
        //console.log("altura viewport",window.innerHeight);
        if( html.scrollTop  === html.scrollHeight-window.innerHeight){
            console.log("probando.......");
            //dispatch(getAllPosts()) 
            window.scrollTo(0,html.scrollHeight-window.innerHeight-100)
            setTimeout(()=>{
                setTemporal(true)
            },500)
        }

        
    }

    useEffect(()=>{
        window.addEventListener('scroll',handleLoadMore)
        return()=>{
            window.removeEventListener('scroll',handleLoadMore) 
        }
    },[])


    useEffect(()=>{
        if(login){
            if(action==="like"){
                dispatch(addActionUserInPost({postId:tmpPostId,action:'likes'}))
                setEvent(event?false:true)
            }
            if(action==="dislike"){
                dispatch(addActionUserInPost({postId:tmpPostId,action:'dislikes'}))
            }
        }
        // eslint-disable-next-line
    },[login])

    

    const handlerLike = (postId,like)=>{
        //validar que el usuario esté logueado
        if(!login){
            setAction("like")
            setTmpPostId(postId)
            setOpenModal(true)
        }
        else{
            setOpenModal(false)
            console.log("dieron click en me gusta post No. "+postId);
            dispatch(addActionUserInPost({postId,action:'likes'}))
        }
    }

    const handlerDislike = (postId) =>{
        if(!login){
            setAction("dislike")
            setTmpPostId(postId)
            setOpenModal(true)
        }
        else{
            setOpenModal(false)
            console.log("dieron click en NO me gusta post No. "+postId);
            dispatch(addActionUserInPost({postId,action:'dislikes'}))
        }
    }

    return(
        <div style={{
            marginBottom:"5px",
            textAlign: "center"
            }}>
            <h2>POSTS</h2>
            {loading && <CircularProgress disableShrink />}
            {hasError && <NotFound></NotFound>}
            
            {
                posts.map(post =>
                    <PostCard 
                        key={post.id} 
                        content={post}
                        handlerActionLike={(post,setEvent)=>handlerLike(post.id,setEvent)}
                        handlerActionDislike={(post,setEvent)=>handlerDislike(post.id,setEvent)}
                    />
                    )
            }
            
            <ModalLogin openModal={openModal} setOpenModal={setOpenModal}></ModalLogin>
        </div>
    )
}

export default Post