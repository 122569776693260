import moment from 'moment'
import 'moment/locale/es';

export class DateUtil{
    
    static locale(){
        moment.locale('es')
    }

    static formatDate(date){
        return moment(date).format('LL')
    }
}