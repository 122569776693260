import { UNDEF,INV_FIELDS } from "../config/constants";
import { FieldValidationFactory } from "./validation.factory";

export class BaseValidation{
    fieldValidators
    fieldValidationFactory

    constructor(){
        this.fieldValidators = []
        this.fieldValidationFactory = FieldValidationFactory
    }

    isValid(req, res){  
        for( let validator of this.fieldValidators ){  
            //console.log("linea 15",validator); 
            let isValid = validator.evaluate(req, res)
            if(!isValid ){
                return {
                    valid: false,
                    field: validator.jpExpr,
                    msg: validator.failMsg
                }
            }
        }
        if( typeof res[INV_FIELDS] != UNDEF && res[INV_FIELDS].length > 0 ){
            return {
                valid: false,
                msg: "No se encontró el campo"
            }
        } 
        return {
            valid: true,
            msg: ""
        }
    }

    addValidator(validator){
        this.fieldValidators.push(validator)
    }
}