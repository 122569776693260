import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import CommentIcon from '@mui/icons-material/Comment';
import Badge from '@mui/material/Badge';
import { DateUtil } from '../../shared/DateUtils';

const screenSize = window.screen.availWidth;

const styleTitle = screenSize < 435 ?
  {fontSize:'0.9em', textAlign:'left'} : 
  {textAlign:'left'}

const styleDate = screenSize < 435 ?
{textAlign:"left", fontSize:"0.8rem"} :
{textAlign:"left"}

const baseRulesCss = {
  background: "#fefcea"
}

const styleCard = screenSize < 435 ?
{
  ...baseRulesCss,
  marginLeft:"5%",
  maxWidth: "90%",
  marginTop:"5%",
} :
{
  ...baseRulesCss,
  marginTop:"5%",
  marginLeft:"30%", 
  maxWidth: "40%", 
  alignContent:"center",
}



export default function PostCard({
  content,
  handlerActionLike,
  handlerActionDislike
  }) {
    const [like,setLike] = useState(false)
    const [dislike,setDislike] = useState(false)

    useEffect(()=>{
      if(content.likeUser)
        setLike(content.likeUser === 1 ? true : false)
      else
        setLike(false)
        
      if(content.dislikeUser)
        setDislike(content.dislikeUser === 1 ? true : false)
      else
        setDislike(false)
        // eslint-disable-next-line
    },[content])
    
    const actionLike = ()=>{
      handlerActionLike(content,like)
    }

    const actionDislike = () =>{
      handlerActionDislike(content)
    }

  return (
    <Card sx={styleCard}>
      <CardMedia
        component="img"
        sx={
          {
            width: '100%'
          }
        }
        image={content.picture}
        alt="Imagen del post"
      />
      <CardHeader
        
        title={
          <h2 style={styleTitle}>{content.title}</h2>
        }
        subheader={
          <h4 style={styleDate}>
            {DateUtil.formatDate(content.date)}
          </h4>
        }
      />
      
      <CardContent
        sx={screenSize < 435 ? {} : {paddingTop:"0px"}}
      >
        <Typography 
          variant="body2" 
          align="left" 
          color="text.secondary" 
          sx={screenSize < 435 ? {} : {paddingLeft:"10px", paddingRight:"10px"} }>
          {content.body}
        </Typography>
      </CardContent>
      <CardActions disableSpacing sx={{background:"#fae68f"}}>
        <IconButton aria-label="Me gusta" onClick={actionLike}>
          <Badge badgeContent={content.likes} color="primary">
            { like ? <ThumbUpIcon /> : <ThumbUpOutlinedIcon/>}
          </Badge>
        </IconButton>
        <IconButton aria-label="No me gusta" onClick={actionDislike}>
          <Badge badgeContent={content.dislikes} color="warning">
            
            { dislike ? <ThumbDownIcon/> : <ThumbDownAltOutlinedIcon/> }
          </Badge>
        </IconButton>
        <IconButton aria-label="Comentar">
          <Badge badgeContent={7} color="success">
            <CommentIcon />
          </Badge>
        </IconButton>
      </CardActions>
    </Card>
  );
}
